<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                        {{
                            $t("message.edit_m", {
                                m: $t("message.telegram_manager"),
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div
                    class="timeline-items__right rounded-sm w-100 p-4"
                    v-loading="loadingData"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                >
                  <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.branch") }}
                  </span>
                  <el-form-item prop="branch_id">
                  <select-branch
                        :class="mode ? 'inputhome__day' : 'inputhome__night'"
                        :id="form.branch_id"
                         @input="getTelegramUsers"
                        :size="'medium'"
                        v-model="form.branch_id"
                    ></select-branch>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.staff") }}
                  </span>
                  <el-form-item prop="staff_ids">
                      <el-select
                        :value="selected"
                        @input="dispatch"
                        :placeholder="$t('message.staff')"
                        filterable
                        clearable
                        :multiple="true"
                        size="medium"
                        class="d-block"
                        v-model="form.staff_ids"
                        :class="mode ? 'input__day' : 'input__night'"
                          >
                        <el-option
                          v-for="(staff, index) in staffs"
                          :key="'staffs-' + index"
                          :label="staff.name + ' ' + staff.last_name"
                          :value="staff.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.seer") }}
                  </span>
                  <el-form-item prop="seer_ids">
                      <el-select
                        :value="selected_two"
                        @input="dispatchTwo"
                        :placeholder="$t('message.seer')"
                        filterable
                        clearable
                        :multiple="true"
                        size="medium"
                        class="d-block"
                        v-model="form.seer_ids"
                        :class="mode ? 'input__day' : 'input__night'"
                          >
                        <el-option
                          v-for="(staff, index) in staffs"
                          :key="'seers-' + index"
                          :label="staff.name + ' ' + staff.last_name"
                          :value="staff.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.type") }}
                  </span>
                  <el-form-item prop="company_ids">
                    <el-select
                    @input="dispatch"
                    :placeholder="$t('message.type')"
                    :multiple="true"
                    filterable
                    clearable
                    size="medium"
                    class="d-block"
                    v-model="form.types"
                    :class="mode ? 'input__day' : 'input__night'"
                       >
                       <el-option
                      :label="'Аванс'"
                      :value="'advance_salaries'"
                    ></el-option>
                    <el-option
                      :label="'Жалоба'"
                      :value="'complaints'"
                    ></el-option>
                    <el-option
                      :label="'Запросы на отгулы'"
                      :value="'take_assents'"
                    ></el-option>
                    <el-option
                      :label="'Больничные'"
                      :value="'sick_leave'"
                    ></el-option>
                    <el-option
                      :label="'Дополнительная работа'"
                      :value="'additional_works'"
                    ></el-option>
                     <el-option
                      :label="'Отпуск'"
                      :value="'vacation'"
                    ></el-option>
                    <el-option
                      :label="'Ariza'"
                      :value="'applications'"
                    ></el-option>
                    <el-option
                      :label="'Tushuntirish Xati'"
                      :value="'explanatory_letters'"
                    ></el-option>
                    <el-option
                      :label="'Active Users'"
                      :value="'active_work'"
                    ></el-option>
                    <el-option
                      :label="'Online Ishlash'"
                      :value="'online_works'"
                    ></el-option>
                    <el-option
                      :label="'Bonuse'"
                      :value="'bonuses'"
                    ></el-option>
                    <el-option
                      :label="'Sanksiya'"
                      :value="'penalties'"
                    ></el-option>
                    <el-option
                      :label="$t('message.business_trips')"
                      :value="'business_trips'"
                    ></el-option>
                  </el-select>
                  </el-form-item>
                  
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                 
                  <el-form-item prop="branch_id">
                     <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label mb-2">
                     {{$t('message.Send_Manager')}}
                  </span>
                    <el-switch v-model="form.send_manager" />
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    name: "crm-update",
    data() {
        return {
          selected: null,
          selected_two: null,
            filter:{
              branch_id:''
            }
        };
    },
    created() {},
     mounted() {
      if (this.staffs) this.fetchStaffs();
    },
    computed: {
        ...mapGetters({
            rules: "telegramManager/rules",
            model: "telegramManager/model",
            columns: "telegramManager/columns",
            staffs: "staff/telegramStaff",
            mode:"MODE"
        }),
    },
    methods: {
        ...mapActions({
            update: "telegramManager/update",
            show: "telegramManager/show",
            updateInventory: "staff/telegramStaff",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        dispatch(e) {
          this.selected = e;
        },
        dispatchTwo(e) {
          this.selected_two = e;
        },
        fetchStaffs(branch_id) {
          const query = {'branch_id':branch_id };
          this.updateInventory(query)
            .then((res) => {
              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
            });
        },
        getTelegramUsers(val){
          this.selected = null;
          this.form.staff_ids = null;
          this.fetchStaffs(val);
        }
    },
};
</script>
